import { render, staticRenderFns } from "./index.vue?vue&type=template&id=3560799f&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3560799f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\新建文件夹\\米之亚\\code\\WEB\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3560799f')) {
      api.createRecord('3560799f', component.options)
    } else {
      api.reload('3560799f', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=3560799f&scoped=true&", function () {
      api.rerender('3560799f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ImgVerify/index.vue"
export default component.exports