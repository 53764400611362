var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "s-canvas",
      staticStyle: { cursor: "pointer" },
      on: { click: _vm.changeCode },
    },
    [
      _c("canvas", {
        attrs: {
          id: "s-canvas",
          width: _vm.contentWidth,
          height: _vm.contentHeight,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }