var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-container" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "el-form",
          {
            ref: "loginForm",
            staticClass: "login-form",
            attrs: {
              autoComplete: "on",
              model: _vm.loginForm,
              rules: _vm.loginRules,
              "label-position": "left",
            },
          },
          [
            _c("h3", { staticClass: "title" }, [
              _vm._v("Enterprise certification platform"),
            ]),
            _c("p", { staticClass: "tips" }),
            _c(
              "el-form-item",
              { attrs: { prop: "username" } },
              [
                _c("el-input", {
                  attrs: {
                    "suffix-icon": "iconfont icon-yonghu_zhanghao_wode",
                    name: "username",
                    type: "text",
                    autoComplete: "on",
                    placeholder: "请输入登录账号",
                  },
                  model: {
                    value: _vm.loginForm.username,
                    callback: function ($$v) {
                      _vm.$set(_vm.loginForm, "username", $$v)
                    },
                    expression: "loginForm.username",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "password" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "password",
                    type: _vm.pwdType,
                    autoComplete: "on",
                    placeholder: "请输入密码",
                  },
                  model: {
                    value: _vm.loginForm.password,
                    callback: function ($$v) {
                      _vm.$set(_vm.loginForm, "password", $$v)
                    },
                    expression: "loginForm.password",
                  },
                }),
                _c("span", {
                  class:
                    _vm.pwdType === "password"
                      ? "icon iconfont icon-xianshi_chakan"
                      : "icon iconfont icon-yincang",
                  staticStyle: {
                    position: "absolute",
                    top: "16px",
                    right: "33px",
                  },
                  on: { click: _vm.showPwd },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "code" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "code",
                    autoComplete: "off",
                    placeholder: "请输入验证码",
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleLogin.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.loginForm.code,
                    callback: function ($$v) {
                      _vm.$set(_vm.loginForm, "code", $$v)
                    },
                    expression: "loginForm.code",
                  },
                }),
                _c("identify", {
                  staticClass: "imgCode",
                  model: {
                    value: _vm.identifyCode,
                    callback: function ($$v) {
                      _vm.identifyCode = $$v
                    },
                    expression: "identifyCode",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-button",
              { attrs: { type: "text" }, on: { click: _vm.handleRegister } },
              [_vm._v("没有账号？立即注册！")]
            ),
            _c(
              "el-button",
              {
                directives: [{ name: "waves", rawName: "v-waves" }],
                staticStyle: {
                  width: "100%",
                  background: "#4452d5",
                  "font-size": "24px",
                  height: "50px",
                },
                attrs: { type: "primary", loading: _vm.loading },
                nativeOn: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.handleLogin.apply(null, arguments)
                  },
                },
              },
              [_vm._v(" 登 录 ")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }